import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.closeMenuTrigger = this.closeMenuTrigger.bind(this);
    }

    menuTrigger() {
        document.querySelector(".header-wrapper").classList.toggle("menu-open");
    }

    closeMenuTrigger() {
        document.querySelector(".header-wrapper").classList.remove("menu-open");
    }

    render() {
        return (
            <header
                className={`header-area formobile-menu header--transparent`}
            >
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                <img
                                    src="/assets/images/logo/logo.png"
                                    alt="Popasul Dupa Deal"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li onClick={this.closeMenuTrigger}>
                                    <Link to="/">Acasă</Link>
                                </li>
                                <li onClick={this.closeMenuTrigger}>
                                    <Link to="/despre">Despre noi</Link>
                                </li>
                                <li onClick={this.closeMenuTrigger}>
                                    <Link to="/galerie">Galerie</Link>
                                </li>
                                <li onClick={this.closeMenuTrigger}>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span
                                onClick={this.menuTrigger}
                                className="menutrigger text-white"
                            >
                                <FiMenu />
                            </span>
                        </div>
                        <div className="close-menu d-block d-lg-none">
                            <span
                                onClick={this.closeMenuTrigger}
                                className="closeTrigger"
                            >
                                <FiX />
                            </span>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
export default Header;
