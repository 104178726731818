import React, { Component } from "react";
import {
    FiMoon,
    FiMonitor,
    FiCoffee,
    FiBookOpen,
    FiTv,
    FiSun,
} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMoon />,
        title: "4 camere cu baie proprie",
        description:
            "Vă punem la dispoziție camere duble cu băi propri, mari și încăpătoare.",
    },
    {
        icon: <FiCoffee />,
        title: "Bucătărie echipată complet",
        description:
            "Bucătărie unde puteți găti micul-dejun, pranzul sau cina pentru familia dumneavoastră.",
    },
    {
        icon: <FiTv />,
        title: "Living",
        description:
            "Livingul, un spațiu dedidat socializării, unde să va relaxați în timpul liber.",
    },
    {
        icon: <FiMonitor />,
        title: "Sală de mese",
        description: "Sală de mese cu o capacitate de 20 de persoane.",
    },
    {
        icon: <FiBookOpen />,
        title: "Terasă",
        description: "Terasă cu vedere la livada noastră de cireși.",
    },
    {
        icon: <FiSun />,
        title: "Grădină",
        description: "O grădină mare, verde și spațioasă.",
    },
];

class Services extends Component {
    render() {
        let title = "Vă oferim";
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                >
                                    <div className="service service__style--2">
                                        <div className="icon">{val.icon}</div>
                                        <div className="content">
                                            <h3 className="title">
                                                {val.title}
                                            </h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Services;
