import React, { Component } from "react";
import { FaFacebookF } from "react-icons/fa";

const SocialShare = [
    {
        Social: <FaFacebookF />,
        link: "https://www.facebook.com/Agropensiunea-Popasul-dupa-Deal-114547653710714",
    },
];
class Footer extends Component {
    getLeftColumnFooter = () => {
        return (
            <div className="footer-left">
                <div className="inner">
                    <span>Vrei o rezervare?</span>
                    <h2>
                        Hai să <br /> ne auzim
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                        <span>Contactează-ne</span>
                    </a>
                </div>
            </div>
        );
    };

    getRightColumnFooter = () => (
        <div className="footer-right" data-black-overlay="6">
            <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                    <div className="footer-link">
                        <ul className="ft-link">
                            <li>
                                <a href="/despre">Despre noi</a>
                            </li>
                            <li>
                                <a href="/galerie">Galerie</a>
                            </li>
                            <li>
                                <a href="/contact">Contact</a>
                            </li>
                            {SocialShare.map((val, i) => (
                                <li key={i}>
                                    <a href={`${val.link}`} rel="noopener noreferrer" target="_blank">
                                        {val.Social}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12 col-lg-6 col-sm-6 col-12">
                    <div className="footer-logo d-flex justify-content-center align-items-center h-100">
                        <img src="/assets/images/logo/logo_inverted.png" alt="Popasul dupa Deal" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="copyright-text">
                        <p>Copyright © 2021 MY ATTITUDE. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );

    generateClassNames = () => {
        const { left, right } = this.props.footerSettings;
        if (left && right) {
            return "col-lg-6";
        }
        if (left || right) {
            return "col-lg-12";
        }
    };

    render() {
        const { left, right } = this.props.footerSettings;
        return (
            <footer className="footer-area">
                <div className="footer-wrapper">
                    <div className="row align-items-end row--0">
                        <div className={this.generateClassNames()}>{left && this.getLeftColumnFooter()}</div>
                        <div className={this.generateClassNames()}>{right && this.getRightColumnFooter()}</div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;
