import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../component/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import SliderTwo from "../component/slider/SliderTwo";
import LazyLoad from "react-lazyload";

const data = {
    title: "Popasul după deal",
    description: 'este o agropensiune nouă, construită în anul 2020. Se situează într-o zonă foarte liniștită din sud-vestul județului Maramureș, în Curtuiușu Mic, din comuna Copalnic-Mănăștur. Înconjurată de câmpii verzi, pomi roditori și fiind aproape de pădure, locația este perfectă pentru un weekend de activități sportive în zonă, zile de relaxare sau, pur și simplu, pentru conectare cu natura și pentru a descoperi o parte a Maramureșului mai puțin cunoscută, dar la fel de captivantă.',
    titleFunds: "Despre proiect",
    funds: "Construită cu fonduri europene .....",
};

export const About = () => {
    const { title, description, titleFunds, funds } = data;

    // Left here to avoid compilation error until used
    console.log(titleFunds, funds);
    return (
        <>
            <PageHelmet pageTitle="Despre" />
            <Breadcrumb title={"Despre"} />

            <div className="rn-about-area ptb--120 bg_color--1">
                <div className="rn-about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <LazyLoad once offset={100}>
                                        <img
                                            className="w-100"
                                            src="/assets/images/about/about-1.jpg"
                                            alt="Popasul dupa deal"
                                        />
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">
                                            {description}
                                        </p>
                                    </div>
                                    <div className="row mt--30">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                {/* <h3 className="title">
                                                    {titleFunds}
                                                </h3>
                                                <p>{funds}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rn-finding-us-area rn-finding-us bg_color--1 container">
                <div className="inner">
                    <div className="content-wrapper">
                        <div className="content">
                            <h4>Despre zona</h4>
                            <p>
                                Curtuiușu Mic este unul dintre cele 12 sate ale
                                comunei Copalnic Mănăștur, din sud-estul
                                județului Maramureș. Satul este deluros,
                                liniștit, cu multe livezi, câmpii verzi și
                                aproape de pădure. La 20 de km se află orașul
                                Baia Mare și la 30 de km, stațiunea Cavnic.
                            </p>
                            <a
                                className="rn-btn"
                                href="https://www.google.com/maps/place/Curtuiu%C8%99u+Mic+437106/@47.5439589,23.6131165,14z/data=!3m1!4b1!4m5!3m4!1s0x4737d81d5c4a9657:0xba4d9d90df7197b8!8m2!3d47.5443537!4d23.6295914?hl=en"
                                rel="noopener noreferrer" target="_blank"
                            >
                                Vezi hartă
                            </a>
                        </div>
                    </div>
                    <div className="thumbnail">
                        <div className="image">
                            <LazyLoad>
                                <img
                                    src="/assets/images/about/finding-us-01.webp"
                                    alt="Finding Images"
                                />
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-area ptb--120 bg_color--1 container pt-sm-2">
                <SliderTwo />
            </div>

            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </>
    );
};
