import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../settings/slick";

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--2',
        title: 'Copalnic-Mănăștur',
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--15',
        title:' Copalnic-Deal'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--12',
        title: 'Preluca Veche',
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--13',
        title: 'Cheile Lăpușului',
    }
]
class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                {SlideList.map((value , index) => (
                    <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
  }
}
export default SliderOne;