import React, { useState } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../settings/slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const GallerySlider = ({ imagesData }) => {
    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
    const [mainGalleryImageIndex, setMainGalleryImageIndex] = useState(0);
    return (
        <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                {imagesData.map((value, index) => (
                    <div className="slide" key={index} data-black-overlay="8">
                        {isGalleryModalOpen && (
                            <Lightbox
                                mainSrc={imagesData[mainGalleryImageIndex].image}
                                nextSrc={imagesData[(mainGalleryImageIndex + 1) % imagesData.length]}
                                prevSrc={imagesData[(mainGalleryImageIndex + imagesData.length - 1) % imagesData.length]}
                                onCloseRequest={() => setIsGalleryModalOpen(false)}
                                onMovePrevRequest={() =>
                                    setMainGalleryImageIndex(
                                        (mainGalleryImageIndex + imagesData.length - 1) % imagesData.length
                                    )
                                }
                                onMoveNextRequest={() =>
                                    setMainGalleryImageIndex((mainGalleryImageIndex + 1) % imagesData.length)
                                }
                            />
                        )}
                        <img
                            src={value.thumbnail}
                            alt="Room images"
                            onClick={() => {
                                setIsGalleryModalOpen(true);
                                setMainGalleryImageIndex(index);
                            }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default GallerySlider;
