import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { Homepage } from "./views/Homepage";
import { About } from "./views/About";
import { Contact } from "./views/Contact";
import { RoomDetails } from "./views/RoomDetails";
import { error404 } from "./views/error404";
import Gallery from "./views/Gallery";
import Footer from "./component/common/Footer";
import Header from "./component/common/Header";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
    footerSettings = {
        right: true,
        left: true,
    };
    render() {
        return (
            <BrowserRouter basename={"/"}>
                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />
                <Switch>
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/`}
                        component={Homepage}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/contact`}
                        component={Contact}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/despre`}
                        component={About}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/camera/:roomId`}
                        component={RoomDetails}
                    />
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/galerie`}
                        component={Gallery}
                    />
                    <Route component={error404} />
                </Switch>
                <Footer footerSettings={this.footerSettings} />
            </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
