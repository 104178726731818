import React from "react";

import { useParams } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../component/common/Breadcrumb";
import GallerySlider from "../component/slider/GallerySlider";

const rooms = [
    {
        id: "camera-dubla-1",
        bradcrumbText: "Camera Dublă",
        title: "Camera dublă cu baie proprie",
        imagesData: [
            {
                thumbnail: "/assets/images/portfolio/rooms/1/1.webp",
                image: "/assets/images/portfolio/rooms/1/1.webp",
            },
            {
                thumbnail: "/assets/images/portfolio/rooms/1/2.webp",
                image: "/assets/images/portfolio/rooms/1/2.webp",
            },
        ],
        price: "120 lei",
        features: [
            "1 pat dublu",
            "Televizor",
            "Internet",
            "Masă",
            "Dulap",
            "Noptiere",
            "Baie cu dus",
        ],
    },
    {
        id: "camera-dubla-2",
        bradcrumbText: "Camera Dublă",
        title: "Camera dublă cu baie proprie si balcon",
        imagesData: [
            {
                thumbnail: "/assets/images/portfolio/rooms/2/1.webp",
                image: "/assets/images/portfolio/rooms/2/1.webp",
            },
            {
                thumbnail: "/assets/images/portfolio/rooms/2/2.webp",
                image: "/assets/images/portfolio/rooms/2/2.webp",
            },
        ],
        price: "120 lei",
        features: [
            "1 pat dublu",
            "Televizor",
            "Internet",
            "Masă",
            "Dulap",
            "Noptiere",
            "Baie cu dus",
            "Balcon"
        ],
    },
    {
        id: "camera-dubla-3",
        bradcrumbText: "Camera Dublă",
        title: "Camera dublă cu baie proprie si balcon",
        imagesData: [
            {
                thumbnail: "/assets/images/portfolio/rooms/3/1.webp",
                image: "/assets/images/portfolio/rooms/3/1.webp",
            },
            {
                thumbnail: "/assets/images/portfolio/rooms/3/2.webp",
                image: "/assets/images/portfolio/rooms/3/2.webp",
            },
        ],
        price: "120 lei",
        features: [
            "1 pat dublu",
            "Televizor",
            "Internet",
            "Masă",
            "Dulap",
            "Noptiere",
            "Baie cu dus",
            "Balcon"
        ],
    },
    {
        id: "camera-dubla-4",
        bradcrumbText: "Camera Dublă",
        title: "Camera dublă cu baie proprie",
        imagesData: [
            {
                thumbnail: "/assets/images/portfolio/rooms/4/1.webp",
                image: "/assets/images/portfolio/rooms/4/1.webp",
            },
            {
                thumbnail: "/assets/images/portfolio/rooms/1/2.webp",
                image: "/assets/images/portfolio/rooms/4/2.webp",
            },
        ],
        price: "120 lei",
        features: [
            "1 pat dublu",
            "Televizor",
            "Internet",
            "Masă",
            "Dulap",
            "Noptiere",
            "Baie cu dus",
        ],
    },
];

export const RoomDetails = () => {
    const { roomId } = useParams();
    const room = rooms.find((r) => r.id === roomId);

    return (
        <>
            <PageHelmet pageTitle="Detalii camera" />
            <Breadcrumb title={room.bradcrumbText} />

            {/* Start content area */}
            <div className="rn-room-details-area ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-sm-12 col-md-12 col-lg-7">
                                            <GallerySlider
                                                imagesData={room.imagesData}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-5 ptb--50">
                                            <div className="content">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3>
                                                                    {room.title}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="title">
                                                            Tarif: {room.price}{" "}
                                                            / noapte
                                                        </h4>
                                                    </div>
                                                </div>
                                                <h4 className="title">
                                                    Facilități
                                                </h4>
                                                <ul className="list-style">
                                                    {room.features.map(
                                                        (feature) => (
                                                            <li>{feature}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </>
    );
};
