import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../component/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import LazyLoad from "react-lazyload";

const TabOne = [
    {
        image: "/assets/images/portfolio/dp-portfolio-01.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-02.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-03.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-04.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-05.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-06.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-07.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-08.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-09.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-10.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-11.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-12.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/1/1.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/1/2.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/2/1.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/1/2.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/3/1.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/3/2.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/4/1.webp",
    },
    {
        image: "/assets/images/portfolio/rooms/4/2.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-13.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-14.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-15.webp",
    },
    {
        image: "/assets/images/portfolio/dp-portfolio-16.webp",
    },
];

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;

        return (
            <div>
                <PageHelmet pageTitle="Galerie" />
                <Breadcrumb title={"Galerie"} />

                <main className="page-wrapper">
                    <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
                        <div className="container">
                            <div className="row">
                                {TabOne.map((value, index) => (
                                    <div className="col-lg-6" key={index}>
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={TabOne[tab1].image}
                                                nextSrc={
                                                    TabOne[
                                                        (tab1 + 1) %
                                                            TabOne.length
                                                    ]
                                                }
                                                prevSrc={
                                                    TabOne[
                                                        (tab1 +
                                                            TabOne.length -
                                                            1) %
                                                            TabOne.length
                                                    ]
                                                }
                                                onCloseRequest={() =>
                                                    this.setState({
                                                        isOpen: false,
                                                    })
                                                }
                                                onMovePrevRequest={() =>
                                                    this.setState({
                                                        tab1:
                                                            (tab1 +
                                                                TabOne.length -
                                                                1) %
                                                            TabOne.length,
                                                    })
                                                }
                                                onMoveNextRequest={() =>
                                                    this.setState({
                                                        tab1:
                                                            (tab1 + 1) %
                                                            TabOne.length,
                                                    })
                                                }
                                            />
                                        )}
                                        <div className="item-portfolio-static">
                                            <div
                                                onClick={() =>
                                                    this.setState({
                                                        isOpen: true,
                                                        tab1: index,
                                                    })
                                                }
                                            >
                                                <div className="portfolio-static">
                                                    <div className="thumbnail-inner">
                                                        <div className="thumbnail">
                                                            <LazyLoad once offset={100}>
                                                                <img
                                                                    src={value.image}
                                                                    alt="Portfolio Images"
                                                                />
                                                            </LazyLoad>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </main>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
            </div>
        );
    }
}

export default Gallery;
