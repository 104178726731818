import React from "react";
import LazyLoad from "react-lazyload";

const data = {
    title: "Popasul după deal",
    description:
        "este o agropensiune nouă, construită în anul 2020. Se situează într-o zonă foarte liniștită din sud-vestul județului Maramureș, în Curtuiușu Mic, din comuna Copalnic-Mănăștur. Înconjurată de câmpii verzi, pomi roditori și fiind aproape de pădure, locația este perfectă pentru un weekend de activități sportive în zonă, zile de relaxare sau, pur și simplu, pentru conectare cu natura și pentru a descoperi o parte a Maramureșului mai puțin cunoscută, dar la fel de captivantă.",
    titleFunds: "Despre proiect",
    funds: "Construită cu fonduri europene .....",
};

export const About = () => {
    const { title, description, titleFunds, funds } = data;

    // Left here to avoid compilation error until used
    console.log(titleFunds, funds);
    return (
        <React.Fragment>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="thumbnail">
                                <LazyLoad height={500} offset={100}>
                                <img
                                    className="w-100"
                                    src="/assets/images/about/about-1.jpg"
                                    alt="Popasul dupa deal"
                                />
                                </LazyLoad>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{title}</h2>
                                    <p className="description">{description}</p>
                                </div>
                                <div className="row mt--30 mt_sm--10">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                            {/* <h3 className="title">{titleFunds}</h3> */}
                                            {/* <p>{funds}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
