import React, { Component } from "react";

class Slider extends Component {
    render() {
        return (
            <div className="slider-activation">
                <div
                    className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
                    data-white-overlay="1"
                ></div>
            </div>
        );
    }
}
export default Slider;
