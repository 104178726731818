import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Landing from "../sections/Landing";
import Services from "../sections/Services";
import { About } from "../sections/About";
import Rooms from "../sections/Rooms";
import Helmet from "../component/common/Helmet";
import LazyLoad from "react-lazyload";

export const Homepage = () => {
    return (
        <>
            <Helmet pageTitle="Popasul după deal" />

            <div className="slider-wrapper">
                <Landing />
            </div>

            <div className="about-area about-position-top ptb--80">
                <About />
            </div>

            <div className="thumbnail pr--40 pl--40 text-center">
                <LazyLoad height={200} offset={100}>
                    <img
                        src="/assets/images/about/afir.jpg"
                        alt="Popasul dupa deal"
                        width="700px"
                    />
                </LazyLoad>
            </div>

            <div className="service-area ptb--80  bg_image bg_image--3">
                <div className="container">
                    <Services />
                </div>
            </div>

            <div className="portfolio-area ptb--120 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55">
                    <Rooms />
                </div>
            </div>

            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </>
    );
};
