import React from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMapPin } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../component/common/Breadcrumb";


export const Contact = () => {
    return (
        <React.Fragment>
            <PageHelmet pageTitle="Contact" />
            <Breadcrumb title={"Contact"} />

            <div className="rn-contact-top-area ptb--120 bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiHeadphones />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Număr de telefon</h4>
                                    <p>
                                        <a href="tel:+40 723283129">
                                            0723 283 129
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMail />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Adresă de e-mail</h4>
                                    <p>
                                        <a href="mailto:miclebogdan28@gmail.com">
                                            miclebogdan28@gmail.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMapPin />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Adresă</h4>
                                    <p>
                                        Strada Principală, Nr. 49A, Curtuiușu Mic, jud. Maramureș<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </React.Fragment>
    );
};
