import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../settings/slick";

const PortfolioList = [
    {
        image: "image-1",
        id: "camera-dubla-1",
    },
    {
        image: "image-2",
        id: "camera-dubla-2",
    },
    {
        image: "image-3",
        id: "camera-dubla-3",
    },
    {
        image: "image-4",
        id: "camera-dubla-4",
    },
];

class Rooms extends Component{
    render(){
        let title = 'Cazare',
        description = 'Vă punem la dispoziție 4 camere duble, două dintre ele cu balcon. Fiecare cameră are pat dublu și baie proprie.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2 class="title">{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...slideSlick}>
                            {PortfolioList.map((value, index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={`/camera/${value.id}`}>
                                                    Vezi camera
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Rooms;
