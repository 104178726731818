import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

export const error404 = () => {
    return (
        <>
            <div className="error-page-inner bg_color--4 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h3 className="sub-title">
                                    Pagina nu a fost gasită!
                                </h3>
                                <span>Pagina pe care o cauți nu există.</span>
                                <div className="error-button">
                                    <a
                                        className="rn-button-style--2 btn-solid"
                                        href="/"
                                    >
                                        Înapoi la paina de pornire
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
        </>
    );
};
